export default function () {
  const isTagDialogVisible = ref(false)
  const updatedSavedTags = ref<string[]>([])
  const tagSuggestions = ref<string[]>([])
  const areOrdersProcessing = ref(false)

  const fetchTagSuggestions = async () => {
    const { fetchOrders, useAccountOrder } = useOrder()
    areOrdersProcessing.value = true
    const ordersWithTags = await fetchOrders({ withDefinedTags: true })
    const accountOrdersWithTags =
      ordersWithTags?.map((order) => useAccountOrder(order)) || []
    tagSuggestions.value = (
      Array.from(
        new Set(
          accountOrdersWithTags
            ?.flatMap((obj) => obj.tags)
            .filter((item) => item),
        ),
      ) as string[] | []
    ).sort()
    areOrdersProcessing.value = false
  }
  const openTagDialogWithSuggestions = async () => {
    isTagDialogVisible.value = true
    await fetchTagSuggestions()
  }
  return {
    isTagDialogVisible,
    updatedSavedTags,
    tagSuggestions,
    areOrdersProcessing,
    fetchTagSuggestions,
    openTagDialogWithSuggestions,
  }
}
